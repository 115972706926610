import { Component, Vue, Watch } from "vue-property-decorator";
import request from "@/request";
import moment from "moment";
import { Form, Modal, Message } from "view-design";

interface Search {
	status:number | null,
	createTimeRange:Date[] | null,
	search:string,
	template:number | null
}

@Component
export default class TableCommon extends Vue {
	dataSource:any[] = [];
	tableLoading:boolean = false;
	form:Search = {
		status: null,
		createTimeRange: null,
		search: "",
		template: null
	};
	page:number = 1;
	total:number = 1;
	template:{ name:string, id:number }[] = [];

	get height ():number {
		return document.body.clientHeight - 274;
	}

	get pageSize ():number {
		return Math.floor((this.height - 36) / 40);
	}

	readonly type:number = 0;

	getEvent (page:number = 1) {
		// console.log(page);
		const type = this.type;
		this.tableLoading = true;
		this.page = page;
		request({
			url: "/repair/rest/projectevent/",
			params: {
				eventtype: type,
				page,
				pagesize: this.pageSize,
				status: this.form.status,
				createtime__range: ((timeRange:Date[] | null):string | null => {
					if (timeRange && timeRange[ 0 ]) {
						return [ moment(timeRange[ 0 ]).format("YYYY-MM-DDT00:00:00"), moment(timeRange[ 1 ]).format("YYYY-MM-DDT23:59:59") ].join();
					} else {
						return null;
					}
				})(this.form.createTimeRange),
				sensorwarn__isnull: true,
				ordering: "-createtime",
				floor: this.$store.state.floorValue,
				template: this.form.template,
				ProjecteventElement__relatetype: this.$store.state.relatetype,
				ProjecteventElement__relatedid: this.$store.state.relatedid
			}
		}).then(({ data }) => {
			this.tableLoading = false;
			this.total = data.count;
			this.dataSource = data.results.map((t:any) => ({
				space: t.space,
				facility: t.facility_name,
				name: t.describe,
				start: t.createtime.replace(/T.+/, ""),
				related: (<string[]>t.fzr).join(" ") + " " + (t.pjr || ""),
				id: <number>t.id,
				actionable: <boolean>t.isactor,
				loading: <boolean>t.isdoing,
				status: t.status,
				isactor: t.isactor,
				guIdList: ((list:any[]) => {
					if (list && list.length > 0) {
						const temp = JSON.stringify(list);
						const results = temp.match(/{"roomguid":.+?}/);
						if (results) {
							return { roomguid: JSON.parse(results[ 0 ]).roomguid };
						} else {
							return null;
						}
					} else {
						return null;
					}
				})(t.dbidlist)
			}));
		});
	}

	rest () {
		(this.$refs.search as Form).resetFields();
		this.getEvent(1);
	}

	removeCell (id, index) {
		// @ts-ignore
		Modal.confirm({
			title: "<p class='del_text_wrap'>确认删除?</p>",
			loading: true,
			onOk: () => {
				request({
					url: `/repair/rest/projectevent/${ id }/`,
			        method: "DELETE"
				}).then(() => {
					// @ts-ignore
					Modal.remove();
					// @ts-ignore
					Message.info("删除成功");
					this.getEvent(this.page);
				});
			}
		});
	}
	mounted () {
		request({
			url: "/repair/rest/flowtemplate/",
			params: {
				flowtype: 3 - this.type
			}
		}).then(({ data }) => {
			this.template = data.map((t:any) => ({
				name: t.name.replace(/班组/, ""),
				id: t.id
			}));
		});
	}
	@Watch("$store.state.relatedid")
	onrelatedidchange () {
		this.getEvent(1);
	}
}

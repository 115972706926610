
	import { Component, Vue, Prop, Watch } from "vue-property-decorator";
	import request from "../../../request";
	import moment from "moment";
	import qs from "qs";
	import {
		AutoComplete,
		Button,
		Cascader,
		DatePicker,
		Form,
		FormItem,
		Modal,
		Option,
		Select,
		Upload,
		Input
	} from "view-design";
	import { Route } from "vue-router";

	interface Tree {
		label:string,
		value:number,
		type:"system" | "category" | "device",
		loading?:boolean,
		children?:Tree[]
	}

	interface resolve {
		what:string,
		how:string,
		why:string
	}

	@Component({
		components: {
			Form,
			FormItem,
			Select,
			Option,
			DatePicker,
			AutoComplete,
			Upload,
			Button,
			Cascader,
			Modal,
            Input
		}
	})
		export default class addGongdan extends Vue {
            @Prop() readonly addShow:any;
            myshow = false;
            floorId:any ="";
            roomId: any = "";
            how = "";
            requirement:any = [];
            mytext: any = "";
            isCamera = false;
            floorTree:Tree[] = [];
            deviceTree:Tree[] = [];
            deviceType:any[] = [];
            resolve:string[] = [];
            info = {
                space: [1],
                device: [],
                deadline: new Date(Date.now() + 7 * 24 * 3600 * 1000),
                priority: 3,
                describe: "",
                fileList: []
            };
            loading:boolean = false;
            imageUrl:string = "";
            visible:boolean = false;
            readonly option = {
                disabledDate (date:Date):boolean {
                    return date && date.valueOf() < Date.now();
                }
            };
            private resolveSource:resolve[] = [];
            private readonly listener = this.$store.subscribe(mutation => {
                if (mutation.type === "findRoom" && mutation.payload.path) {
                    this.info.space = mutation.payload.path;
                }
            });
        changeText (id) {
            // let temp = {};
            // this.requirement.forEach(val => {
            //     if (val.id == id) {
            //         temp = val;
            //     }
            // });
            // this.info.describe = temp.what + "," + temp.why;
        }
        loadDevicedata (item:Tree, callback:Function) {
			item.loading = true;
            // 监控，设备
            if (item.value == 11542) {
                 request(`/smt/rest/camera/?format=json&format=json&pagesize=999&room__floor=${ this.floorId }&room=${ this.roomId }`).then(({ data }) => {
                    item.children = data.results.map((t:any) => ({
                        label: t.name,
                        value: t.id
                    }));
                    item.loading = false;
                    callback();
                });
            } else {
                request(`/device/device/?format=json&format=json&devicetype=${ item.value }&pagesize=999&room__floor=${ this.floorId }&room=${ this.roomId }`).then(({ data }) => {
                    item.children = data.results.map((t:any) => ({
                        label: t.serialnumber,
                        value: t.id
                    }));
                    item.loading = false;
                    callback();
                });
            }
        }
		loadDevice (item:Tree, callback:Function) {
			item.loading = true;
			let url:string;
			let params;
			if (item.type === "system") {
				url = "/device/devicecategory/";
				params = {
					DeviceType__mepsystemtype: item.value,
					DeviceType__Device__room__floor: this.$store.state.floorValue,
					DeviceType__Device__room: this.info.space[ 2 ]
				};
			} else {
				url = "/device/device2/";
				params = {
					devicetype__device_category: item.value,
					room: this.info.space[ 2 ],
					room__floor: this.$store.state.floorValue
				};
			}
			request({
				url,
				params
			}).then(({ data }) => {
				if (url === "/device/devicecategory/") {
					item.children = data.map((t:any):Tree => ({
						label: t.name,
						value: t.id,
						loading: false,
						type: "category",
						children: []
					}));
				} else {
					item.children = data.results.map((t:any):Tree => ({
						label: t.device_category_name + t.code,
						value: t.id,
						type: "device"
					}));
				}
				item.loading = false;
				callback();
			});
		}

		searchDescribe (text:string) {
			if (text === "") {
				this.resolve = [];
			} else {
				let regexp:RegExp = new RegExp(text);
				let set:Set<string> = new Set();
				this.resolveSource.forEach(t => {
					if (regexp.test(t.what)) {
						set.add(t.what);
					}
				});
				if (set.size === 0) {
					set.add("没有相关建议");
				}
				this.resolve = Array.from(set);
			}
		}

		focusToRoom (value:number[]) {
            this.roomId = "";
            this.floorId = "";
			if (value && value.length === 3) {
				// request(`/space/room/${ value[ 2 ] }/`).then(({ data }) => {
				// 	this.$store.commit("findRoom", { id: data.room_guid, floor: value[ 1 ] });
				// });
                this.roomId = value[ 2 ];
			}
            if (value && value.length >= 2) {
                this.floorId = value[1];
            }
            this.info.device = [];
            this.getDeviceType();
		}

		previewFile (file:any) {
			let url = file.response.data.fileurl;
			if (!file.response.data.image) {
				window.open(url);
			} else {
				this.imageUrl = url;
				this.visible = true;
			}
		}

		handleExceeded () {
			this.$Notice.error({ desc: "文件大小超出限制" });
		}

		submit () {
            if (this.info.space.length != 3) {
                this.$Message.info("请选择关联楼宇");
                return;
            }
            if (!this.info.describe) {
                this.$Message.info("请填写问题描述");
                return;
            }
			this.loading = true;
			const tempData = this.info;
			const data = {
				opt: "create",
				describe: tempData.describe,
				mepsystem: tempData.device[ 1 ],
				priority: tempData.priority,
				docids: (this.$refs.upload as any).fileList.map((t:any) => t.response.data.docId),
				space: tempData.space,
				deadline: tempData.deadline ? moment(tempData.deadline).format("YYYY-MM-DD") : null,
                how: this.how
			};
            this.isCamera = tempData.device[ 0 ] == 11542;
            // 关联设备，或着摄像头
            data[this.isCamera ? "camera" : "device"] = tempData.device[ 1 ];
			request({
				url: "/repair/eventopt/",
				method: "post",
				transformRequest: [ function (data) {
					return qs.stringify(data, { arrayFormat: "brackets" });
				} ],
				data
			}).then(() => {
			    this.loading = false;
                this.close();
			});
		}
        close () {
            this.myshow = false;
            this.$emit("close", false);
        }
        getDeviceType () {
            request("/device/devicetype/?format=json").then((data) => {
                let temp = [];
                data.data.forEach(val => {
                    temp.push({
                        value: val.id,
                        label: val.name,
                        children: [],
                        loading: false
                    });
                });
                this.deviceType = temp;
            });
        }
        focusToText (value:number[]) {
            if (value && value.length == 2) {
                request(`/repair/rest/requirement/?format=json&devicetype=${ value[0] }`).then(res => {
                    this.resolveSource = res.data;
                });
            } else {
                request("/repair/rest/requirement/?format=json").then(res => {
                    this.resolveSource = res.data;
                });
            }
        }
        selectHow (value, index) {
            // console.log("ceshi", value, index);
            this.how = "";
            this.resolveSource.forEach(val => {
                if (val.what + "," + val.why == value) {
                    this.how = val.how;
                }
            });
        }
		mounted () {
			request("/prj/facilityfloorrooms/").then(({ data }) => {
				this.floorTree = [ data.data[ 0 ] ];
			});
            this.getDeviceType();
            this.focusToText([]);
		}
        @Watch("addShow")
        onaddShowchange (val) {
            this.info.space = [];
            this.info.device = [];
            this.info.deadline = new Date(Date.now() + 7 * 24 * 3600 * 1000);
            this.info.priority = 3;
            this.info.describe = "";
            (this.$refs.upload as any).clearFiles();
            this.myshow = true;
        }

        @Watch("$store.state.addRepair.add")
        onaddRepairchange (val) {
            this.info.space = [1, this.$store.state.addRepair.floorid, this.$store.state.addRepair.roomid ];
            this.floorId = this.$store.state.addRepair.floorid;
            this.roomId = this.$store.state.addRepair.roomid;
            this.myshow = true;
        }

		beforeRouteLeave (to:Route, from:Route, next:Function) {
			this.listener();
			next();
		}
	}


	import { Component, Watch } from "vue-property-decorator";
	import TableCommon from "@/components/tableCommon/TableCommon";
	import { Button, DatePicker, Form, FormItem, Input, Option, Page, Select, Table, TableColumn, Modal } from "view-design";
	import { CreateElement, VNode } from "vue";
	import Loading from "@/components/loading/Loading.vue";
	import request from "@/request";
	import addGongdan from "./component/add.vue";
	import gongdanContent from "./component/content.vue";

	const step0 = require("@/assets/dealing.png");
	const step1 = require("@/assets/未开始.png");
	const step2 = require("@/assets/处理中.png");
	const step3 = require("@/assets/已完成.png");
	const step4 = require("@/assets/已关闭.png");
	interface CurrentTableColumn extends Omit<TableColumn, "render"> {
		render?:(
			h:CreateElement,
			params:TableColumnRenderParams
		) => VNode
	}

	declare class TableColumnRenderParams {
		row:any;
		column:any;
		index:number;
	}

	@Component({
		components: {
            Modal, addGongdan, gongdanContent,
			Loading,
			DatePicker,
			Page,
			Table,
			Form,
			FormItem,
			Select,
			Option,
			Input,
			Button,
			iButton: Button
		}
	})
		export default class RepairTable extends TableCommon {
            addShow = 0;
            gongdanTitle = "查看";
            gongdanID = "";
            typeName = "";
            gongdanShow = false;
            myheight:number = 750;
            readonly type:number = 2;
            isDrawBack = false;
            hasAuth:any = false;
            delAuth = false;
            get columns ():CurrentTableColumn[] {
                return [
                    {
                        title: "关联楼宇",
                        key: "space",
                        width: 170,
                        align: "center"
                    },
                    {
                        title: "描述",
                        ellipsis: true,
                        align: "center",
                        tooltip: true,
                        key: "name"
                    },
                    {
                        title: "开始时间",
                        key: "start",
                        align: "center",
                        width: 100
                    },
                    {
                        title: "进度",
                        align: "center",
                        width: 50,
                        render (h:CreateElement, params:TableColumnRenderParams):Vue.VNode {
                            let src, status;
                            const paramsStatus = params.row.status + "";
                            if (params.row.loading) {
                                src = step0;
                                status = "请稍候";
                            } else if (paramsStatus === "1") {
                                src = step1;
                                status = "新建";
                            } else if (paramsStatus === "2") {
                                src = step2;
                                status = "处理中";
                            } else if (paramsStatus === "3") {
                                src = step3;
                                status = "已解决";
                            } else {
                                src = step4;
                                status = "已关闭";
                            }
                            return h("div", [ h("img", {
                                attrs: {
                                    src,
                                    title: status
                                },
                                style: {
                                    "width": "30px",
                                    "vertical-align": "middle",
                                    "margin-right": ".5rem"
                                }
                            })
                            //  h("span", status)
                            ]);
                        }
                    },
                    {
                        title: "处理、评价人",
                        ellipsis: true,
                        align: "center",
                        tooltip: true,
                        key: "related"
                    },
                    {
                        title: "操作",
                        align: "center",
                        width: 120,
                        className: "colOperation",
                        render: (h:CreateElement, params:TableColumnRenderParams):Vue.VNode => {
                            return h(
                                "div",
                                {
                                    style: {
                                        "display": "flex",
                                        "justify-content": "space-evenly"
                                    }
                                },
                                [
                                    h(
                                        "img",
                                        {
                                            attrs: {
                                                src: require("@/assets/detail.png"),
                                                title: "查看"
                                            },
                                            style: {
                                                cursor: "pointer"
                                            },
                                            on: {
                                                click: () => {
                                                    this.gongdanTitle = "查看";
                                                    this.typeName = "detail";
                                                    this.gongdanID = params.row.id;
                                                    this.gongdanShow = true;
                                                    // this.$router.push(`/repair/detail/${ params.row.id }/`);
                                                }
                                            }
                                        }
                                    ),
                                    ((!params.row.loading) && (params.row.guIdList && ((this.$store.state.showType === "room" && params.row.guIdList.roomguid))) ? h(
                                        "img",
                                        {
                                            attrs: {
                                                src: require("@/assets/location.png"),
                                                title: "处理"
                                            },
                                            style: {
                                                cursor: "pointer",
                                                width: "25px"
                                            },
                                            on: {
                                                click: () => {
                                                    request({
                                                        url: "/space/room/",
                                                        params: {
                                                            room_guid: params.row.guIdList.roomguid
                                                        }
                                                    }).then(({ data }) => {
                                                        const temp = data.results[ 0 ];
                                                        this.$store.commit("findRoom", {
                                                            floor: temp.floorid,
                                                            id: params.row.guIdList.roomguid,
                                                            path: [ temp.floorfacilityid, temp.floorid, temp.id ]
                                                        });
                                                    });
                                                }
                                            }
                                        }
                                    ) : h("img", { attrs: { width: 25 }, staticStyle: { visibility: "hidden" } }),
                                    (params.row.loading || !params.row.isactor || (params.row.status == "4" || !params.row.actionable)) ? h("img", {
                                        attrs: { width: 25 },
                                        staticStyle: { visibility: "hidden" }
                                    }) : h(
                                        "img",
                                        {
                                            attrs: {
                                                src: require("@/assets/operation.png"),
                                                title: "处理"
                                            },
                                            style: {
                                                cursor: "pointer"
                                            },
                                            on: {
                                                click: () => {
                                                    this.gongdanTitle = "处理";
                                                    this.typeName = "operation";
                                                    this.gongdanID = params.row.id;
                                                    this.gongdanShow = true;
                                                    // this.$router.push(`/repair/operation/${ params.row.id }/`);
                                                }
                                            }
                                        }
                                    )),
                                    h(
                                        "img",
                                        {
                                            attrs: {
                                                src: require("@/assets/img/2.png"),
                                                title: "删除"
                                            },
                                            style: {
                                                cursor: "pointer",
                                                display: this.delAuth ? "" : "none"
                                            },
                                            on: {
                                                click: () => {
                                                    // console.log(params.row.id, params.index);
                                                    this.removeCell(params.row.id, params.index);
                                                }
                                            }
                                        }
                                    )
                                ]
                            );
                        }
                    }
                ];
            };
        addClose () {
			this.getEvent(1);
        }
        getAuth () {
            return new Promise((resolve, reject) => {
                request("/user/self/").then(({ data }) => {
                    this.delAuth = data.data.superuser;
                    resolve(data.data.permissions.indexOf("分析分配班组") > -1);
                });
            });
        }
		mounted ():void {
			// console.log("啊啊啊啊");
            this.getAuth().then((res:boolean) => {
                this.hasAuth = res;
			    this.getEvent(1);
            });
            this.$nextTick(() => {
                this.myheight = document.querySelector("body").clientHeight - 233;
            });
		}

		@Watch("$store.state.floorValue")
		onFloorChange () {
			this.getEvent(1);
		}

        @Watch("isDrawBack")
        onisDrawBackchange (val) {
            this.$store.commit("changeDraw", val);
        }
	}



// @ts-nocheck
	import { Component, Vue, Watch, Prop } from "vue-property-decorator";
	import request from "../../../request";
	import Loading from "@/components/loading/Loading.vue";
	import moment from "moment";
	import { Button, Message, Table } from "view-design";
	import normal from "@/components/repairSteps/normal.vue";
	import qs from "qs";
	import OperationStep from "@/components/repairSteps/operationStep.vue";
	import OperationFileList from "@/components/fileList/operationFileList.vue";
	import { Route } from "vue-router";

interface step {
	name: string,
	apart?: { operator: string, description: string, doc?: string[], voice?: string, completeTime?: string, how?: string }[]
}

interface file {
	filetype: string,
	fileurl: string,
	name: string,
	shortname: string
}

	@Component({
	components: {
		OperationFileList,
		Loading,
		Distribution: resolve => (require(["@/components/repairSteps/distribution.vue"], resolve)),
		Normal: resolve => (require(["@/components/repairSteps/normal.vue"], resolve)),
		Evaluate: resolve => (require(["@/components/repairSteps/evaluate.vue"], resolve)),
		Bill: resolve => (require(["@/components/repairSteps/bill.vue"], resolve)),
		Button,
		OperationStep,
		Table
	},
	filters: {
		getTimeString(value: Date | undefined): string {
			if (typeof value === "undefined") {
				return "暂未指定";
			} else {
				return moment(value).format("YYYY-MM-DD");
			}
		}
	}
})
		export default class OperationRoot extends Vue {
            @Prop() readonly paramId:any;
            @Prop() readonly typeName:any;
            type: string = "Loading";
            fromName = "";
            loading: boolean = false;
            errorSpaceString: string = "";
            data: { [other: string]: string | undefined | Array<number> | Date } = {
                stepName: undefined,
                element: undefined,
                errorSpace: [],
                priority: undefined,
                template: undefined,
                principal: undefined,
                createTime: undefined,
                deadline: undefined,
                attitude: undefined,
                quality: undefined,
                timeliness: undefined,
                device: [],
                creator: ""
            };
            templateArray: { text: string, value: number }[] = [];
            step: step[] = [];
            spares: { name: string, number: number }[] = [];
            btnList: { value: "" | number, text: string }[] = [{ value: "", text: "确认" }];
            file: file[] = [];
            columns = [
                {
                    title: "名称",
                    key: "name"
                },
                {
                    title: "数量",
                    key: "number"
                }
            ];
            private MainLink: HTMLLinkElement = document.createElement("link");

	get templateName (): any {
		return this.data.template_name;
	}

	private get id (): string {
		// return this.$route.params.id;
		return this.paramId;
	}
    closeAll () {
        this.$emit("close");
    }
    clickBack () {
        if (this.fromName === "energyWater") {
            this.$router.push({
                path: "/energy/water/"
            });
        } else {
            this.$router.push({
                path: this.data.eventtype === "1" ? "/repair/maintenance/" : "/repair/repair/"
            });
        }
    }
	submit (oid: string = "") {
		const data:any = (this.$refs.operation as any).getInfo(oid);
		// this.loading = true;
		request({
			url: "/repair/eventopt/",
			method: "post",
			data,
			[this.type === "bill" ? "" : "transformRequest"]: [function (data: any) {
				return qs.stringify(data, { encode: false, arrayFormat: "brackets" });
			}]
		}).then(() => {
			this.$store.commit("findRoom", {
				floor: ""
			});
			// this.$router.go(-1);
            this.closeAll();
		});
	}
	mounted () {
		this.renderCss();
		let stepId: number;
		let stepIdURL: string;
		request({
			url: `/repair/rest/projectevent/${ this.id }/`
		}).then(({ data }) => {
			if (data.status && data.status.toString() === "4" && this.typeName === "operation") {
				// @ts-ignore
				Message.info("维修单已处理完毕！");
				// this.$router.go(-1);
                this.closeAll();
				return;
			}
			this.data = {
				stepName: "分析分配",
				element: data.space || data.facility_name,
				errorSpace: [],
				priority: data.priority,
				template: ((): string | undefined => {
					if (/watch/.test(this.$route.fullPath)) {
						return data.template_name;
					} else {
						return data.template === null ? "暂未分配" : data.template.match(/\/\d+\//)[0].replace(/\//g, "");
					}
				})(),
				template_name: data.template_name,
				principal: "",
				createTime: new Date(data.createtime),
				deadline: data.deadline,
				attitude: (data.attitude != 0 && data.attitude != 1) ? 2 : data.attitude,
				quality: (data.quality != 0 && data.quality != 1) ? 2 : data.quality,
				timeliness: (data.speed != 0 && data.speed != 1) ? 2 : data.speed,
				device: data.device,
				creator: data.createusername,
                deviceTypeName: data.deviceTypeName,
                camera: data.camera,
                camera_id: data.camera_id,
                device_id: data.device_id,
                deviceType_id: data.deviceType_id,
                isactor: data.isactor,
                eventtype: data.eventtype
			};
			if (data.dbidlist && data.dbidlist.length > 0) {
				const temp = JSON.stringify(data.dbidlist);
				const results = temp.match(/{"roomguid":.+?}/);
				if (results) {
					const uniqueId = JSON.parse(results[0]).roomguid;
					request({
						url: "/space/room/",
						params: {
							room_guid: uniqueId
						}
					}).then(({ data }) => {
						const temp = data.results[0];
						this.$store.commit("findRoom", {
							floor: temp.floorid,
							id: uniqueId,
							path: [temp.floorfacilityid, temp.floorid, temp.id]
						});
					});
				}
			}
			stepId = data.curflowstep_html;
			stepIdURL = data.curflowstep ? data.curflowstep.match(/\/\d+\//)[0].replace(/\//g, "") : "";
		}).then(() => {
			request(`/repair/event/${ this.id }/info/`).then(({ data }) => {
				const info = data.data.event;
				this.errorSpaceString = info.equipment;
				const line: step[] = [];
				line.push({
					name: /自动生成/.test(info.createuser) ? "维保策略自动生成" : "创建工单",
					apart: [
						{
							operator: info.createuser,
							description: info.describe,
							doc: info.createfile,
							voice: info.voicedoc_id,
							completeTime: info.createtime,
							how: info.how,
                            space: info.space,
                            deivce: info.deivce,
                            template: info.template_id,
                            deadline: info.deadline,
                            priority: info.priority,
                            devicetype: this.data.deviceType_id,
                            camera: this.data.camera_id
						}
					]
				});
				if (Array.isArray(info.beforestep)) {
					info.beforestep.forEach((t: any) => {
						line.push({
							name: t.name,
							apart: t.opt.map((t: any) => ({
								operator: t.actor,
								description: t.comment,
								doc: t.files,
								voice: t.voice,
								completeTime: t.oprtime,
								editable: t.editable,
								id: t.id
							}))
						});
					});
				}
				/* if (typeof this.data.stepName === "string" && this.data.stepName.length > 0) {
					line.push({ name: this.data.stepName });
				} */
				if (Array.isArray(info.behindstep) && info.behindstep.length > 0 && info.behindstep[0] !== this.data.stepName) {
					line.push({ name: info.behindstep[0] });
				}
				if (Array.isArray(info.spares) && info.spares.length > 0) {
					this.spares = info.spares;
					this.spares.push({ name: "总额", number: info.maintainFee });
				}
				this.step = line;
                // console.log(this.step);
				if (this.data.template === "暂未分配") {
					this.data.template = info.template_id;
				}
				if (stepId) {
					request({
						url: `/repair/rest/flowtemplatestep/${ stepIdURL }/`
					}).then(({ data }) => {
						let type = "";
						switch (stepId.toString()) {
							case "0":
								type = "normal";
								break;
							case "1":
								type = "evaluate";
								break;
							case "2":
								type = "distribution";
								break;
							case "3":
								type = "bill";
								break;
						}
                        this.type = type;
						this.data.stepName = data.name;
						this.data.principal = data.actorname ? data.actorname.join() : "暂无负责人";
						this.loading = false;
					});
				} else if (this.typeName !== "detail") {
					this.type = "distribution";
					this.data.principal = "暂无负责人";
					this.loading = false;
				} else {
					this.loading = false;
				}

				const value: number[] = info.devicePath || [];
				if (value.length > 1) {
					request({
						url: `/device/devicecategory/${ value[1] }/`
					}).then(({ data }) => {
						(data.files.length > 0) && (this.file = this.file.concat(data.files));
					});
					request({
						url: "/file/rest/doc2relate/",
						params: {
							relatetype: "systemtype",
							relateid: value[0]
						}
					}).then(({ data }) => {
						(data.length > 0) && (this.file = this.file.concat(data));
					});
				}
				if (value.length > 2) {
					request({
						url: "/file/rest/doc2relate/",
						params: {
							relateid: value[2],
							relatetype: "device"
						}
					}).then(({ data }) => {
						(data[0].document.length > 0) && (this.file = this.file.concat(data[0].document));
					});
				}
			});
		});
		request({
			url: "/repair/flowopt/",
			params: {
				type: /repair/.test(this.$route.path) ? "wx" : "wb"
			}
		}).then(({ data }) => {
			data.data.forEach((value: any) => {
				if (!/报警/.test(value.name)) {
					this.templateArray.push({ text: value.name, value: value.id });
				}
			});
		});
		request({
			url: `/repair/event/${ this.id }/flowstepoperation/`
		}).then(({ data }) => {
			if (data.res === "succ") {
				if (this.typeName === "deviceEdit") {
					this.btnList = [{ value: 0, text: "修改" }];
				} else {
					this.btnList = data.data.map((t: any) => ({ value: t.id, text: t.name }));
				}
			}
		});
	}

	@Watch("$store.state.baseColor")
	onColorChange () {
		this.renderCss();
	}
    beforeRouteEnter (to: Route, from: Route, next: Function): void {
        let temp = from.name;
        // console.log(from, to, temp);
		next(vm => {
            vm.fromName = temp;
        });
	}
	beforeRouteLeave (from: Route, to: Route, next: Function): void {
		this.MainLink.remove();
		next();
	}

	private renderCss () {
		less.render(lessFiles.operation, {
			javascriptEnabled: true,
			modifyVars: {
				"@baseColor": this.$store.state.baseColor,
				"path": "/static/UnityWeb/desktop/"
			},
			paths: ["/static/UnityWeb/desktop/"]
		}).then(data => {
			this.MainLink && this.MainLink.remove();
			const href: string = URL.createObjectURL(new Blob([data.css]));
			const link = document.createElement("link");
			link.href = href;
			link.rel = "stylesheet";
			document.head.appendChild(link);
			this.MainLink = link;
		});
	}
	}
